export default [
  {
    header: 'Home',
    title: '',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'home',
    action: 'read',
  },
  {
    header: 'Usuarios',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Lista de Usuarios',
        route: 'usuarios',
        icon: 'UsersIcon',
        resource: 'usuarios',
        action: 'read',
      },
      {
        title: 'Crear Usuario',
        route: 'usuarios-create',
        icon: 'UserIcon',
        resource: 'usuarios',
        action: 'create',
      },
    ],
  },
  {
    header: 'Categorías',
    icon: 'TagIcon',
    children: [
      {
        title: 'Lista de Categorías',
        route: 'categorias',
        icon: 'TagIcon',
        resource: 'categorias',
        action: 'read',
      },
      {
        title: 'Crear Categoría',
        route: 'categorias-create',
        icon: 'TagIcon',
        resource: 'categorias',
        action: 'create',
      },
    ],
  },
  {
    header: 'Ubicaciones',
    icon: 'MapPinIcon',
    children: [
      {
        title: 'Lista de Ubicaciones',
        route: 'ubicaciones',
        icon: 'MapPinIcon',
        resource: 'ubicaciones',
        action: 'read',
      },
      {
        title: 'Crear Ubicación',
        route: 'ubicaciones-create',
        icon: 'MapPinIcon',
        resource: 'ubicaciones',
        action: 'create',
      },
    ],
  },
  {
    header: 'Clientes',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Lista de Clientes',
        route: 'clientes',
        icon: 'UsersIcon',
        resource: 'clientes',
        action: 'read',
      },
      {
        title: 'Crear Cliente',
        route: 'clientes-create',
        icon: 'UserIcon',
        resource: 'clientes',
        action: 'create',
      },
    ],
  },
  {
    header: 'Proveedores',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Lista de Proveedores',
        route: 'proveedores',
        icon: 'UsersIcon',
        resource: 'proveedores',
        action: 'read',
      },
      {
        title: 'Crear Proveedor',
        route: 'proveedores-create',
        icon: 'UserIcon',
        resource: 'proveedores',
        action: 'create',
      },
    ],
  },
  {
    header: 'Productos',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Lista de Productos',
        route: 'productos',
        icon: 'BoxIcon',
        resource: 'productos',
        action: 'read',
      },
      {
        title: 'Crear Producto',
        route: 'productos-create',
        icon: 'BoxIcon',
        resource: 'productos',
        action: 'create',
      },
    ],
  },
  {
    header: 'Salidas',
    icon: 'ChevronsUpIcon',
    children: [
      {
        title: 'Lista de Salidas',
        route: 'salidas',
        icon: 'ChevronsUpIcon',
        resource: 'salidas',
        action: 'read',
      },
      {
        title: 'Crear Salida',
        route: 'salidas-create',
        icon: 'ChevronsUpIcon',
        resource: 'salidas',
        action: 'create',
      },
    ],
  },
  {
    header: 'OCs',
    icon: 'ChevronsDownIcon',
    children: [
      {
        title: 'Listar OCs',
        route: 'compras',
        icon: 'ChevronsDownIcon',
        resource: 'compras',
        action: 'read',
      },
      {
        title: 'Crear OC',
        route: 'compras-create',
        icon: 'ChevronsDownIcon',
        resource: 'compras',
        action: 'create',
      },
    ],
  },
]
